.validatebtn {
    border-radius: 35px;
}

figure {
    width: 100%;
}

#uptest {
    width: 100%;
    display: block;
}