  .navbar-dark .navbar-toggler {
      background: #313145;
  }

  @media (min-width: 350px) and (max-width: 767px) {
      .navbar-dark .navbar-toggler {
          margin-left: auto;
          margin-right: auto;
          right: 3%;
          display: inline-block;
          position: absolute;
      }
  }